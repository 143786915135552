import React from "react";
import "../css/Maintenance.css";
import logo from "../assets/Logo - Blue.svg";
import img from "../assets/image1.gif";

export default function Maintenance() {
    return(
        <>
        <div className="maintenance">
            <img src = {logo} style={{height:"50%", width:"50%"}}/>
        </div>
            <h1 style={{color:"white"}}>Under Development</h1>
            <img src = {img} style={{height:"50%", width:"50%"}}/>
            </>
    );
}
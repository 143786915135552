import logo from './logo.svg';
import './App.css';
import "./components/Maintenance";
import Maintenance from './components/Maintenance';
function App() {
  return (
    <div className="App">
      <Maintenance/>
    </div>
  );
}

export default App;
